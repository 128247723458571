// Dashboard.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, CircularProgress, Container, Typography, Alert } from '@mui/material';
import { CSVLink } from 'react-csv';
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import logo from './trendix-logo.png';


function Dashboard() {
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState([]);
    const [error, setError] = useState('');
    const [showAll, setShowAll] = useState(false);
    const auth = getAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, user => {
            if (!user) {
                navigate("/login");
            }
        });

        // Clean up the listener on unmount
        return () => unsubscribe();
    }, [auth, navigate]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axios(process.env.REACT_APP_API_URL + '/signals');
                setRows(result.data.map((row, index) => ({ id: index, ...row })));
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const columns = [
        { field: 'date', headerName: 'Date', width: 130 },
        { field: 'bloomberg_ticker', headerName: 'Bloomberg Ticker', width: 200 },
        { field: 'prediction', headerName: 'Signal', width: 130, valueFormatter: (params) => params.value.toFixed(3) },

    ];

    const logout = async () => {
        try {
            await signOut(auth);
            navigate("/login");
        } catch (error) {
            console.error("Error signing out: ", error);
        }
    };

    return (
        <Container>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, }}>
                <img src={logo} alt="Trendix Logo" style={{ width: '200px', marginBottom: '20px' }} /> {/* Add the logo */}

                <Button variant="contained" onClick={logout}>Logout</Button>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                {loading ? (
                    <CircularProgress />
                ) : error ? (
                    <Alert severity="error">{error}</Alert>
                ) : (
                    <>
                        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                            <Button variant="contained" color="primary">
                                <CSVLink style={{ color: 'white', textDecoration: 'none' }} data={rows} filename={"data.csv"}>
                                    Export to CSV
                                </CSVLink>
                            </Button>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box sx={{ width: '49%', mb: 2 }}>
                                <Typography variant="h6">Top 100 Longs</Typography>
                                <DataGrid
                                    rows={rows.slice(0, 100)}
                                    columns={columns}
                                    sortModel={[{ field: 'prediction', sort: 'desc' }]}
                                />
                            </Box>
                            <Box sx={{ width: '49%', mb: 2 }}>
                                <Typography variant="h6">Top 100 Shorts</Typography>
                                <DataGrid
                                    rows={rows.slice(-100)}
                                    columns={columns}
                                    sortModel={[{ field: 'prediction', sort: 'asc' }]}
                                />
                            </Box>
                        </Box>


                    </>
                )}
            </Box>
        </Container >
    );
}

export default Dashboard;
